import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "urls"
    }}>{`URLs`}</h1>
    <p>{`If the URL can be located without it, drop the prefix `}<a parentName="p" {...{
        "href": "http://www."
      }}>{`http://www.`}</a>{` A good rule of thumb is to test
the URL without the prefix first.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`More product information is available at workday.com.`}</li>
      </ul>
    </Do>
    <p>{`When writing how to interact with a link, don't use "click on" or "click." Instead, try to link a
naturally-occurring phrase in the sentence, or link an entire short phrase.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Show `}<strong parentName="li">{`additional jobs`}</strong>{` in San Francisco, CA.`}</li>
        <li parentName="ul"><strong parentName="li">{`Show jobs.`}</strong></li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      